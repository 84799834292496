import React from "react"
import Layout from "../components/layout"
import { Parallax } from "react-parallax"
import BgImage from "../../static/img/bg2.jpg"
import CharlieWilson from "../../static/img/ceo-charlie-wilson.jpg"
import IconCharities from "../../static/img/icon-charities.png"
import IconCircularEconomy from "../../static/img/icon-circular-economy.png"
import IconSolarSolutions from "../../static/img/icon-solar-solutions.png"
import CompanyImg1 from "../../static/img/company-img-1.jpg"
import SalvexMap from "../../static/img/salvex-map.png"
import LyricCentre from "../../static/img/lyric-centre.jpg"

const CompanyPage = () => (
  <Layout>
    <Parallax bgImage={BgImage} className="text-center parallax-secondary">
      <div className="container-fluid">
        <div className="row">
          <div className="col" />
          <div className="col-md-7">
            <h1 className="text-white subtitle wow fadeInDown">COMPANY</h1>
            <h3 className="text-white wow fadeInUp subtitle">
              Salvex is a global auction platform that handles the sale of
              surplus, salvage, scrap, damaged, bankrupt inventories for asset
              recovery, insurance, transportation
            </h3>
          </div>
          <div className="col" />
        </div>
      </div>
    </Parallax>
    <div className="container-fluid pb-4">
      <div class="container mb-4">
        <div className="row">
          <div className="col pt-4">
            <h2 className="text-primary mt-4 text-center">OUR MISSION</h2>
          </div>
        </div>
        <div className="row">
          <div className="col" />
          <div className="col-md-8">
            <p className="mt-4 mb-4 text-center">
              Salvex implements sustainable procurement programs at global
              organizations by identifying and efficiently redeploying
              underutilized assets.
            </p>
          </div>
          <div className="col" />
        </div>
      </div>
    </div>
    <div
      className="container-fluid bg-primary"
      style={{ position: "relative" }}
    >
      <div
        className="col-md-5 bg-warning"
        style={{
          position: "absolute",
          height: "2px",
          top: "6rem",
          left: "0rem",
        }}
      />
      <div class="container mb-4">
        <div className="row">
          <div class="col-md-7 col-founder bg-primary mt-4 pb-4">
            <h3 className="text-warning font-weight-normal pt-4 mt-4 mb-0">
              Charlie Wilson
            </h3>
            <h4 className="text-warning font-weight-normal mt-0 mb-4">
              CEO, Salvex Group, Inc
            </h4>
            <p className="mt-4 text-white">
              Charlie Wilson, has been dedicated for 30+ years creating
              solutions in the circular economy through repurposing commercial
              assets. Prioritizing social responsibility has always been our
              mission and our global team has successfully offset over 250,000
              tons of CO<sup>2</sup>.{" "}
            </p>
          </div>
          <div class="col-md-5 text-center pb-0 pt-0">
            <img
              src={CharlieWilson}
              alt="Charlie Wilson, CEO"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid pb-4 mb-4">
      <div className="container">
        <div className="row">
          <div className="col pt-4">
            <h2 className="text-primary mt-4 text-center">Strategic Goals</h2>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-12 col-md-4">
            <p className="text-center mt-4 mb-4">
              <img
                src={IconSolarSolutions}
                alt="Cost efficient asset repurposing"
              />
            </p>
            <h3 className="card-title text-secondary text-center h2 font-weight-normal">
              Cost Efficient Asset Repurposing
            </h3>
            <p className="card-text text-center">
              Salvex utilizes sustainable procurement solutions to reduce asset
              storage fees and avoid new equipment purchasing costs. We identify
              and implement efficient strategic asset reuse programs.
            </p>
          </div>
          <div className="col-sm-12 col-md-4">
            <p className="text-center mt-4 mb-4">
              <img src={IconCircularEconomy} alt="Global asset marketplace" />
            </p>
            <h3 className="card-title text-secondary text-center h2 font-weight-normal">
              Global Asset Marketplace
            </h3>
            <p className="card-text text-center">
              Salvex’s proprietary redeployment platform has created and
              maintained a global asset marketplace used by an extensive
              corporate and nonprofit network across all major industries.
            </p>
          </div>
          <div className="col-sm-12 col-md-4">
            <p className="text-center mt-4 mb-4">
              <img
                src={IconCharities}
                alt="Sustainable Corporate Procurement"
              />
            </p>
            <h3 className="card-title text-secondary text-center h2 font-weight-normal">
              Sustainable Corporate Procurement
            </h3>
            <p className="card-text text-center">
              Salvex develops, implements and advocates the improved corporate
              adoption of the UN Global Compact's sustainability best practices,
              focusing on corporate procurement practices.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-alt pt-4 mt-4 pb-4 mb-4">
      <div className="container pb-4">
        <div className="row pb-4">
          <div className="col-md-5">
            <img src={LyricCentre} className="img-fluid" />
          </div>
          <div className="col-md-7">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3463.5146674139246!2d-95.36690698553456!3d29.76275623878059!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640bf3066490c2b%3A0x89978f0e185db2db!2s440+Louisiana+St%2C+Houston%2C+TX+77002!5e0!3m2!1sen!2sus!4v1557853099342!5m2!1sen!2sus"
              width="100%"
              height="366"
              frameborder="0"
              style={{ border: "0" }}
              allowfullscreen
            />
            <h5 className="text-green-4 mt-4">Corporate Headquarters</h5>
            <p className="text-green-4">
              440 Louisiana St., Suite 550
              <br />
              Houston, TX 77002, USA
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default CompanyPage
